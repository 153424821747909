<template lang="pug">
v-list-item(@click="$emit('click')" :ripple="false")
  //- v-list-item-icon.mr-2(v-if="editMode" color="grey darken-2" rounded size="48")
    v-btn.mr-1(fab depressed color="primary" x-small): v-icon(@click.stop="item.realQuantity++") mdi-plus
    v-btn(fab depressed color="primary" x-small  :disabled="item.realQuantity <= 1"): v-icon(@click.stop="item.realQuantity--") mdi-minus
  v-list-item-avatar.mr-1(
    v-if="!noIcon",
    color="grey darken-3",
    rounded,
    size="48",
    @click.stop="increaseQuantity"
  )
    v-badge.t-badge(
      color="red",
      overlap,
      Zbottom,
      :value="item.realQuantity > 1"
    )
      template(v-slot:badge)
        transition(name="tbadge", mode="out-in")
          .Zprimary--text(:key="item.realQuantity") {{ item.realQuantity }}
      //- v-icon(Zcolor="white") mdi-basket
      v-icon {{ !editMode ? 'mdi-image' : 'mdi-plus' }}
  v-list-item-avatar(
    v-if="editMode",
    color="grey darken-3",
    size="48",
    rounded,
    outline,
    @click.stop="item.realQuantity > 1 ? decreaseQuantity() : null"
  )
    //- v-btn(fab depressed color="primary" x-small  :disabled="item.realQuantity <= 1"): v-icon(@click.stop="item.realQuantity--")
    v-icon(v-if="item.realQuantity > 1") mdi-minus
  v-list-item-content.ml-2.px-1
    v-list-item-title {{ index + 1 }}. {{ item.name }}
    v-list-item-subtitle.grey--text.text--darken-1(v-if="item.realQuantity > 1 && !item.exciseStamp") {{ item.realQuantity | numeral('0,0.[000]') }} {{ $t('unitType.' + item.unitType) }} &times; {{ item.price | numeral('0,0.[00]') }} ₸
      | {{ item.discountValue ? ` - ${$options.filters.numeral(item.discountValue, '0,0.[00]')} ₸ (${$t('скидка')}${item.discountType === 'PERCENTAGE' ? ` ${item.discountPercentage}%` : ''}) ` : '' }}
      | {{ item.markupValue ? ` + ${$options.filters.numeral(item.markupValue, '0,0.[00]')} ₸ (${$t('наценка')}${item.markupType === 'PERCENTAGE' ? ` ${item.markupPercentage}%` : ''}) ` : '' }}
    v-list-item-subtitle(v-if="item.exciseStamp")
      v-chip(small, label, color="indigo", @click.stop="$emit('route', 'scan')")
        v-avatar.mr-0(left)
          v-icon(size="16px", dense) mdi-barcode
        | Маркировка {{ item.exciseStamp }}

  v-list-item-action
    v-list-item-action-text
      //- t-anim-numcss.total.ezcar.font-weight-medium(:number="amount")
    v-list-item-action-text.headline.ezcar {{ amount | numeral('0,0.[00]') }} ₸
    //- v-list-item-action-text.grey--text.text--darken-1(v-if="item.taxes && item.taxes[0]") {{ item.taxes[0].sum.value | numeral('0,0.[00]') }} ₸ ({{ $t('в_т_ч_ндс') }} {{ item.taxes[0].percent / 1000 }}%)
  v-list-item-action(v-if="editMode")
    v-btn(
      depressed,
      fab,
      x-small,
      color="grey darken-3",
      @click.stop="deleteItem(item)"
    ): v-icon(
      color="grey"
    ) mdi-close
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    index: {
      type: Number,
      default: 1,
    },
    noIcon: {
      type: Boolean,
      default: undefined,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // quantity: 1,
  }),

  computed: {
    amount: {
      get() {
        if (this.item.price && this.item.realQuantity) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.item.total = Math.floor(this.item.price * this.item.realQuantity)
        }
        return this.item.total
      },
      set(value) {
        console.debug('total set', value)
        this.item.total = value
      },
    },
  },

  methods: {
    ...mapActions({
      deleteItem: 'tjtheme/deleteItem',
    }),
    increaseQuantity() {
      /* eslint-disable no-plusplus */
      this.item.realQuantity++
      // this.item.total = this.item.realQuantity * this.item.price.value
    },
    decreaseQuantity() {
      this.item.realQuantity--
      // this.item.total = this.item.realQuantity * this.item.price.value
    },

  },
}
</script>
<style lang="stylus"></style>
